<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import find from 'lodash/find';
import snakeCase from 'lodash/snakeCase';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';
import { MuiModal } from '@emobg/motion-ui/v1';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';

import { GoogleMapZonesComponent } from '@/components';
import SidePanel from '../../components/SidePanel';
import { CARRENTAL_PERMISSIONS } from '../../const/permissions';

import carRental from '../../store/CarrentalModuleMap';
import ZoneComponent from './ZoneComponent';

export default {
  name: 'StationZones',
  components: {
    GoogleMapZonesComponent,
    MuiModal,
    SidePanel,
    ZoneComponent,
  },
  mixins: [permissionsMixin],
  props: {
    station: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      googleMapsKey: process.env.GOOGLE_MAPS_KEY,
      address: {},
      isEditing: false,
      isAddingNewZone: false,
      apiPayload: {
        name: '',
      },
      isDeleteModalVisible: false,
      deletingZoneName: '',
      editZoneName: '',
      modalConfig: {
        header: {
          class: 'pl-3',
          title: 'Deleting geofence',
          isClosable: true,
        },
      },
    };
  },
  computed: {
    isZoneValid() {
      return this.apiPayload.name.length > 3 && this.isZoneShapeValid;
    },
    isZoneShapeValid() {
      return !!get(this, 'apiPayload.polygon', []).length;
    },
    hasGeofence() {
      return !isEmpty(get(this.station, 'geofences') || []);
    },
    mapZones() {
      return get(this.station, 'geofences') || [];
    },
    stationMarker() {
      const stationPinpoint = get(this.station, 'pinpoint') || {};
      const stationMarker = {
        latitude: get(stationPinpoint, 'latitude'),
        longitude: get(stationPinpoint, 'longitude'),
        icon: { url: '/carrental/station.svg' },
        uuid: 'station',
        tooltip: get(this.station, 'greenwayName') || '',
      };

      return [stationMarker];
    },
  },
  created() {
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
  },
  methods: {
    ...mapActions(carRental.stations, [
      'postStationGeofence',
      'putStationGeofence',
      'deleteStationGeofence',
      'getStationInfo',
    ]),
    async saveZone() {
      if (isEmpty(this.station.id)) {
        return;
      }
      if (this.isEditing) {
        await this.putStationGeofence({
          stationId: this.station.id,
          geofenceName: this.editZoneName,
          geofence: this.apiPayload,
        });
      } else {
        await this.postStationGeofence({ stationId: this.station.id, geofence: this.apiPayload });
      }
      await this.getStationInfo(this.station.id);
      this.reset();
    },
    updateZoneName(zoneName) {
      this.apiPayload.name = zoneName;
    },
    updateApiPayload(shape) {
      this.apiPayload = { ...this.apiPayload, ...shape };
    },
    deleteZone(zoneName) {
      this.isDeleteModalVisible = true;
      this.deletingZoneName = zoneName;
    },
    async confirmDeleteZone() {
      await this.deleteStationGeofence({ stationId: this.station.id, geofenceName: this.deletingZoneName });
      await this.getStationInfo(this.station.id);
      this.reset();
    },
    startEditZone(zoneName) {
      const zoneToEdit = cloneDeep(find(this.mapZones, { name: zoneName }));
      this.apiPayload = zoneToEdit || { name: '' };
      this.isEditing = true;
      this.isAddingNewZone = false;
      this.editZoneName = zoneName;
    },
    reset() {
      this.resetApiPayload();
      this.editZoneName = '';
      this.deletingZoneName = '';
      this.isDeleteModalVisible = false;
      this.isEditing = false;
      this.isAddingNewZone = false;
    },
    resetApiPayload() {
      this.apiPayload = { name: '' };
    },
    includes,
    snakeCase,
  },
};
</script>

<template>
  <div
    class="emobg-background-color-white"
    data-test-id="station_zones"
  >
    <SidePanel data-test-id="side_panel">
      <template slot="content">
        <template v-if="!hasGeofence">
          <ui-tooltip
            :tooltip="`Search for the address of the station ${station.greenwayId}`"
            class="position-fixed StationZone_location"
          >
            <ui-location-input
              v-if="!isZoneValid"
              :gkey="googleMapsKey"
              data-test-id="content-location_search"
              placeholder="Search location"
              class="position-absolute z-index-30"
              @changeplace="({ detail }) => address = detail"
            />
          </ui-tooltip>
        </template>
        <template v-else>
          <ui-location-input
            v-if="!isZoneValid"
            :gkey="googleMapsKey"
            data-test-id="content-location_search"
            placeholder="Search location"
            class="position-absolute z-index-30"
            @changeplace="({ detail }) => address = detail"
          />
        </template>
        <GoogleMapZonesComponent
          :is-static="!includes(permissions, CARRENTAL_PERMISSIONS.stationEditZones)"
          :address="address.location"
          :full-screen-control="false"
          :marker-sources="stationMarker"
          :zones="mapZones"
          :edit="editZoneName"
          :is-editing="isAddingNewZone"
          data-test-id="content-map"
          @add-shape="updateApiPayload"
          @update-shape="updateApiPayload"
        />
      </template>

      <template slot="sidebar-top">
        <br>
      </template>

      <template slot="sidebar">
        <div
          class="p-3"
          data-test-id="sidebar"
        >
          <h1>Station detail</h1>
          <p class="pt-1 pb-2 mb-5 emobg-font-small emobg-color-ink-light emobg-border-bottom-1 emobg-border-color-ground">
            {{ `${station.greenwayId} - ${station.greenwayName}` }}
          </p>
          <h3 class="mb-1">
            Geofences
          </h3>
          <ZoneComponent
            v-for="(zone, index) in mapZones"
            :key="index"
            :zone-name="zone.name"
            :is-valid-shape="isZoneShapeValid"
            :is-editing="editZoneName === zone.name"
            @editZone="startEditZone"
            @deleteZone="deleteZone"
            @stopEditingMode="reset"
            @updateZoneName="updateZoneName"
            @saveZone="saveZone"
          />
          <div
            v-if="isAddingNewZone || !hasGeofence"
            class="mt-3"
          >
            <template v-if="!hasGeofence && !isAddingNewZone">
              <div class="d-flex align-items-end flex-column emobg-border-radius-small emobg-background-color-primary-lightest p-3">
                <ui-alert
                  :icon="ICONS.alertAloneFull"
                  :color="COLORS.warning"
                  data-test-id="no_geofence-alert"
                  class="mb-3 w-100"
                >
                  To create a station geofence, start by clicking the `Create` button below.
                  On the map, click and trace with the mouse a shape around the station until it's closed.
                </ui-alert>
                <ui-button
                  :disabled="!station.id"
                  data-test-id="create_zone-button"
                  @clickbutton="isAddingNewZone = true"
                >
                  Create
                </ui-button>
              </div>
            </template>
            <template v-else>
              <ZoneComponent
                :is-valid-shape="isZoneShapeValid"
                @stopEditingMode="isAddingNewZone = false"
                @updateZoneName="updateZoneName"
                @saveZone="saveZone"
              />
            </template>
          </div>
          <div
            v-else
            class="d-flex justify-content-end mt-3"
          >
            <ui-button
              :disabled="isEditing"
              data-test-id="add_zone-button"
              @clickbutton="isAddingNewZone = true"
            >
              Add new geofence
            </ui-button>
          </div>
        </div>
      </template>
    </SidePanel>
    <MuiModal
      v-model="isDeleteModalVisible"
      v-bind="modalConfig"
      data-test-id="delete_zone-modal"
      @close-modal="isDeleteModalVisible = false"
    >
      <template slot="body">
        <p class="emobg-color-danger emobg-font-default p-3">
          Are you sure you want to delete this geofence?
        </p>
        <p class="px-3 pb-6">
          Once deleted, all information will be lost
        </p>
      </template>
      <template slot="footer">
        <div class="d-flex align-content-center justify-content-end w-100 p-3">
          <ui-button
            :color="GRAYSCALE.inkLight"
            :face="FACES.text"
            data-test-id="cancel-button"
            class="mr-2"
            @clickbutton="isDeleteModalVisible = false"
          >
            Cancel
          </ui-button>

          <ui-button
            data-test-id="confirm-button"
            @clickbutton="confirmDeleteZone"
          >
            Confirm
          </ui-button>
        </div>
      </template>
    </MuiModal>
  </div>
</template>
