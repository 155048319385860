var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3", attrs: { "data-test-id": "zone-component" } },
    [
      _vm.isEmpty(_vm.zoneName) || _vm.isEditing
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column emobg-border-radius-small emobg-background-color-primary-lightest p-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center mb-1" },
                [
                  _c("p", { staticClass: "mr-2" }, [_vm._v(" Name ")]),
                  _c(
                    "ui-tooltip",
                    {
                      attrs: {
                        tooltip:
                          "Use a descriptive name. The name needs to be unique for station.",
                      },
                    },
                    [
                      _c("ui-icon", {
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          size: _vm.ICONS_SIZES.xSmall,
                          color: _vm.GRAYSCALE.inkLight,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("MuiInputText", {
                staticClass: "w-100",
                attrs: {
                  "data-test-id": "zone_name-input",
                  placeholder: "Geofence name",
                  name: "name",
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _vm.isNameRepeated
                ? _c(
                    "span",
                    {
                      staticClass: "emobg-font-x-small emobg-color-danger mt-1",
                    },
                    [_vm._v(" The name cannot be repeated in the station ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end mt-3" },
                [
                  !_vm.isEmpty(_vm.zoneName)
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "emobg-color-white-contrast",
                          attrs: {
                            face: _vm.FACES.text,
                            color: _vm.COLORS.danger,
                            loading: _vm.isGeofenceDeleting,
                            "data-test-id": "delete-button",
                          },
                          on: { clickbutton: _vm.deleteZone },
                        },
                        [_vm._v(" Delete ")]
                      )
                    : _vm._e(),
                  _c(
                    "ui-button",
                    {
                      staticClass: "emobg-color-white-contrast",
                      staticStyle: { "min-width": "80px" },
                      attrs: {
                        face: _vm.FACES.outline,
                        "data-test-id": "cancel-button",
                      },
                      on: { clickbutton: _vm.stopEditingMode },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "ui-button",
                    {
                      staticClass: "ml-3",
                      staticStyle: { "min-width": "80px" },
                      attrs: {
                        disabled: !_vm.isDataValid,
                        loading: _vm.isGeofenceSaving,
                        "data-test-id": "save-button",
                      },
                      on: { clickbutton: _vm.saveZone },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "p-2 d-flex emobg-color-ink-light justify-content-between emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-small",
            },
            [
              _vm._v(" " + _vm._s(_vm.zoneName) + " "),
              _vm.includes(
                _vm.permissions,
                _vm.CARRENTAL_PERMISSIONS.stationEditZones
              )
                ? _c(
                    "ui-button",
                    {
                      attrs: {
                        face: _vm.FACES.text,
                        size: _vm.SIZES.xSmall,
                        compact: "",
                        "data-test-id": "edit-button",
                      },
                      on: { clickbutton: _vm.editZone },
                    },
                    [_vm._v(" Edit ")]
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }