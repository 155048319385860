<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui/v1';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { contentCells } from './config/stationsViewContentCells';

export default {
  name: 'StationsView',
  components: { MuiAlgoliaList },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
    }),
  },
  created() {
    this.contentCells = contentCells;
  },
};
</script>

<template>
  <div
    class="StationsView px-2 mt-3 px-lg-5"
    data-test-id="stations-view"
  >
    <h1 class="pb-4">
      Stations
    </h1>
    <MuiAlgoliaList
      :filters="`operator_uuid: ${activeOperatorUuid}`"
      :table-config="contentCells"
      data-test-id="stations-list"
      index="vt_carrental_station"
    />
  </div>
</template>
