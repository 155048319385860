var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emobg-background-color-white",
      attrs: { "data-test-id": "station_zones" },
    },
    [
      _c(
        "SidePanel",
        { attrs: { "data-test-id": "side_panel" } },
        [
          _c(
            "template",
            { slot: "content" },
            [
              !_vm.hasGeofence
                ? [
                    _c(
                      "ui-tooltip",
                      {
                        staticClass: "position-fixed StationZone_location",
                        attrs: {
                          tooltip: `Search for the address of the station ${_vm.station.greenwayId}`,
                        },
                      },
                      [
                        !_vm.isZoneValid
                          ? _c("ui-location-input", {
                              staticClass: "position-absolute z-index-30",
                              attrs: {
                                gkey: _vm.googleMapsKey,
                                "data-test-id": "content-location_search",
                                placeholder: "Search location",
                              },
                              on: {
                                changeplace: ({ detail }) =>
                                  (_vm.address = detail),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : [
                    !_vm.isZoneValid
                      ? _c("ui-location-input", {
                          staticClass: "position-absolute z-index-30",
                          attrs: {
                            gkey: _vm.googleMapsKey,
                            "data-test-id": "content-location_search",
                            placeholder: "Search location",
                          },
                          on: {
                            changeplace: ({ detail }) => (_vm.address = detail),
                          },
                        })
                      : _vm._e(),
                  ],
              _c("GoogleMapZonesComponent", {
                attrs: {
                  "is-static": !_vm.includes(
                    _vm.permissions,
                    _vm.CARRENTAL_PERMISSIONS.stationEditZones
                  ),
                  address: _vm.address.location,
                  "full-screen-control": false,
                  "marker-sources": _vm.stationMarker,
                  zones: _vm.mapZones,
                  edit: _vm.editZoneName,
                  "is-editing": _vm.isAddingNewZone,
                  "data-test-id": "content-map",
                },
                on: {
                  "add-shape": _vm.updateApiPayload,
                  "update-shape": _vm.updateApiPayload,
                },
              }),
            ],
            2
          ),
          _c("template", { slot: "sidebar-top" }, [_c("br")]),
          _c("template", { slot: "sidebar" }, [
            _c(
              "div",
              { staticClass: "p-3", attrs: { "data-test-id": "sidebar" } },
              [
                _c("h1", [_vm._v("Station detail")]),
                _c(
                  "p",
                  {
                    staticClass:
                      "pt-1 pb-2 mb-5 emobg-font-small emobg-color-ink-light emobg-border-bottom-1 emobg-border-color-ground",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          `${_vm.station.greenwayId} - ${_vm.station.greenwayName}`
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("h3", { staticClass: "mb-1" }, [_vm._v(" Geofences ")]),
                _vm._l(_vm.mapZones, function (zone, index) {
                  return _c("ZoneComponent", {
                    key: index,
                    attrs: {
                      "zone-name": zone.name,
                      "is-valid-shape": _vm.isZoneShapeValid,
                      "is-editing": _vm.editZoneName === zone.name,
                    },
                    on: {
                      editZone: _vm.startEditZone,
                      deleteZone: _vm.deleteZone,
                      stopEditingMode: _vm.reset,
                      updateZoneName: _vm.updateZoneName,
                      saveZone: _vm.saveZone,
                    },
                  })
                }),
                _vm.isAddingNewZone || !_vm.hasGeofence
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        !_vm.hasGeofence && !_vm.isAddingNewZone
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-end flex-column emobg-border-radius-small emobg-background-color-primary-lightest p-3",
                                },
                                [
                                  _c(
                                    "ui-alert",
                                    {
                                      staticClass: "mb-3 w-100",
                                      attrs: {
                                        icon: _vm.ICONS.alertAloneFull,
                                        color: _vm.COLORS.warning,
                                        "data-test-id": "no_geofence-alert",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " To create a station geofence, start by clicking the `Create` button below. On the map, click and trace with the mouse a shape around the station until it's closed. "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "ui-button",
                                    {
                                      attrs: {
                                        disabled: !_vm.station.id,
                                        "data-test-id": "create_zone-button",
                                      },
                                      on: {
                                        clickbutton: function ($event) {
                                          _vm.isAddingNewZone = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Create ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c("ZoneComponent", {
                                attrs: {
                                  "is-valid-shape": _vm.isZoneShapeValid,
                                },
                                on: {
                                  stopEditingMode: function ($event) {
                                    _vm.isAddingNewZone = false
                                  },
                                  updateZoneName: _vm.updateZoneName,
                                  saveZone: _vm.saveZone,
                                },
                              }),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "d-flex justify-content-end mt-3" },
                      [
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              disabled: _vm.isEditing,
                              "data-test-id": "add_zone-button",
                            },
                            on: {
                              clickbutton: function ($event) {
                                _vm.isAddingNewZone = true
                              },
                            },
                          },
                          [_vm._v(" Add new geofence ")]
                        ),
                      ],
                      1
                    ),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "delete_zone-modal" },
            on: {
              "close-modal": function ($event) {
                _vm.isDeleteModalVisible = false
              },
            },
            model: {
              value: _vm.isDeleteModalVisible,
              callback: function ($$v) {
                _vm.isDeleteModalVisible = $$v
              },
              expression: "isDeleteModalVisible",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c("template", { slot: "body" }, [
            _c(
              "p",
              { staticClass: "emobg-color-danger emobg-font-default p-3" },
              [_vm._v(" Are you sure you want to delete this geofence? ")]
            ),
            _c("p", { staticClass: "px-3 pb-6" }, [
              _vm._v(" Once deleted, all information will be lost "),
            ]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-content-center justify-content-end w-100 p-3",
              },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: _vm.GRAYSCALE.inkLight,
                      face: _vm.FACES.text,
                      "data-test-id": "cancel-button",
                    },
                    on: {
                      clickbutton: function ($event) {
                        _vm.isDeleteModalVisible = false
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "ui-button",
                  {
                    attrs: { "data-test-id": "confirm-button" },
                    on: { clickbutton: _vm.confirmDeleteZone },
                  },
                  [_vm._v(" Confirm ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }