<script>
export default {
  name: 'GeofencesCount',
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    geofencesCount() {
      return this.result.geofences_count || 0;
    },
  },
};
</script>
<template>
  <div>
    <span v-if="geofencesCount">{{ geofencesCount }}</span>
    <ui-badge
      v-else
      :color="COLORS.danger"
    >
      None
    </ui-badge>
  </div>
</template>
