<script>
export default {
  name: 'VehicleCount',
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    count() {
      return this.result.vehicle_count || '0';
    },
  },
};
</script>
<template>
  <div>{{ count }}</div>
</template>
