var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "StationView",
      attrs: { "data-test-id": "station_details-view" },
    },
    [
      _vm.isLoading
        ? _c("ui-loader")
        : [
            _c("StationZones", {
              staticClass: "StationView__station",
              attrs: {
                station: _vm.stationInfo,
                "data-test-id": "station_zones",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }