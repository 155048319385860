var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "StationsView px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "stations-view" },
    },
    [
      _c("h1", { staticClass: "pb-4" }, [_vm._v(" Stations ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          filters: `operator_uuid: ${_vm.activeOperatorUuid}`,
          "table-config": _vm.contentCells,
          "data-test-id": "stations-list",
          index: "vt_carrental_station",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }