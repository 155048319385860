<script>
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import { mapState } from 'vuex';
import { MuiInputText } from '@emobg/motion-ui/v1';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import { CARRENTAL_PERMISSIONS } from '../../const/permissions';
import carRental from '../../store/CarrentalModuleMap';

export default {
  name: 'ZoneComponent',
  components: {
    MuiInputText,
  },
  mixins: [permissionsMixin],
  props: {
    zoneName: {
      type: String,
      default: '',
    },
    isValidShape: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: this.zoneName || '',
    };
  },
  computed: {
    ...mapState(carRental.stations, {
      stationInfo: state => get(state, 'stationInfo.data') || {},
      isGeofenceSaving: state => get(state, 'geofence.STATUS.LOADING'),
      isGeofenceDeleting: state => get(state, 'deleteGeofence.STATUS.LOADING'),
    }),
    isNameRepeated() {
      const geofencesName = filter(map(this.stationInfo.geofences || [], 'name'), stationName => stationName !== this.zoneName);
      return includes(geofencesName, trim(this.name));
    },
    isZoneNameValid() {
      const trimmedName = trim(this.name);
      return trimmedName.length > 3 && !this.isNameRepeated;
    },
    isDataValid() {
      return this.isValidShape && this.isZoneNameValid;
    },
  },
  watch: {
    zoneName() {
      this.name = this.zoneName;
    },
    name() {
      this.$emit('updateZoneName', trim(this.name));
    },
    isEditing() {
      if (!this.isEditing) {
        this.name = this.zoneName;
      }
    },
  },
  created() {
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
  },
  methods: {
    deleteZone() {
      this.$emit('deleteZone', this.zoneName);
    },
    editZone() {
      this.$emit('editZone', this.zoneName);
    },
    stopEditingMode() {
      this.name = this.zoneName;
      this.$emit('stopEditingMode');
    },
    saveZone() {
      this.$emit('saveZone');
    },
    includes,
    isEmpty,
  },
};
</script>

<template>
  <div
    class="mb-3"
    data-test-id="zone-component"
  >
    <div
      v-if="isEmpty(zoneName) || isEditing"
      class="d-flex flex-column emobg-border-radius-small emobg-background-color-primary-lightest p-3"
    >
      <div class="d-flex align-items-center mb-1">
        <p class="mr-2">
          Name
        </p>
        <ui-tooltip tooltip="Use a descriptive name. The name needs to be unique for station.">
          <ui-icon
            :icon="ICONS.infoFull"
            :size="ICONS_SIZES.xSmall"
            :color="GRAYSCALE.inkLight"
          />
        </ui-tooltip>
      </div>
      <MuiInputText
        v-model="name"
        data-test-id="zone_name-input"
        placeholder="Geofence name"
        name="name"
        class="w-100"
      />
      <span
        v-if="isNameRepeated"
        class="emobg-font-x-small emobg-color-danger mt-1"
      >
        The name cannot be repeated in the station
      </span>
      <div class="d-flex justify-content-end mt-3">
        <ui-button
          v-if="!isEmpty(zoneName)"
          :face="FACES.text"
          :color="COLORS.danger"
          :loading="isGeofenceDeleting"
          data-test-id="delete-button"
          class="emobg-color-white-contrast"
          @clickbutton="deleteZone"
        >
          Delete
        </ui-button>
        <ui-button
          :face="FACES.outline"
          data-test-id="cancel-button"
          class="emobg-color-white-contrast"
          style="min-width: 80px;"
          @clickbutton="stopEditingMode"
        >
          Cancel
        </ui-button>
        <ui-button
          :disabled="!isDataValid"
          :loading="isGeofenceSaving"
          data-test-id="save-button"
          class="ml-3"
          style="min-width: 80px;"
          @clickbutton="saveZone"
        >
          Save
        </ui-button>
      </div>
    </div>
    <div
      v-else
      class="p-2 d-flex emobg-color-ink-light justify-content-between emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-small"
    >
      {{ zoneName }}
      <ui-button
        v-if="includes(permissions, CARRENTAL_PERMISSIONS.stationEditZones)"
        :face="FACES.text"
        :size="SIZES.xSmall"
        compact
        data-test-id="edit-button"
        @clickbutton="editZone"
      >
        Edit
      </ui-button>
    </div>
  </div>
</template>
