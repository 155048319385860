import StationLink from '../../components/StationLink';
import VehicleCount from '../components/VehicleCount';
import GeofencesCount from '../components/GeofencesCount';

export const contentCells = [
  {
    title: 'Station ID',
    component: StationLink,
    props: result => ({
      stationUuid: result.objectID,
      stationId: result.station_id,
    }),
  },
  {
    property: 'station_name',
    title: 'Name',
    width: 450,
  },
  {
    component: VehicleCount,
    props: result => ({ result }),
    title: 'Vehicle count',
    width: 50,
  },
  {
    component: GeofencesCount,
    props: result => ({ result }),
    title: 'Geofences',
    width: 50,
  },
];
