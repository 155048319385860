<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';

import StationZones from '../components/StationZones';
import carRental from '../../store/CarrentalModuleMap';
import { scopes } from '../store/StationsModule';

export default {
  name: 'StationDetailsView',
  components: { StationZones },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(carRental.stations, {
      stationInfo: state => get(state, 'stationInfo.data') || {},
    }),
  },
  created() {
    this.stationUuid = this.$route.params.stationUuid;
  },
  async mounted() {
    // We use "isLoading" because we call later again getStationInfo but we dont want the loader in this page.
    this.isLoading = true;
    await this.getStationInfo(this.stationUuid);
    this.isLoading = false;
  },
  beforeDestroy() {
    this.clearData({ scopes: [scopes.stationInfo, scopes.geofence, scopes.deleteGeofence] });
  },
  methods: {
    ...mapActions(carRental.stations, ['getStationInfo']),
    ...mapMutations(carRental.stations, ['clearData']),
  },
};
</script>

<template>
  <div
    class="StationView"
    data-test-id="station_details-view"
  >
    <ui-loader v-if="isLoading" />
    <template v-else>
      <StationZones
        :station="stationInfo"
        data-test-id="station_zones"
        class="StationView__station"
      />
    </template>
  </div>
</template>
