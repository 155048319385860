var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.geofencesCount
        ? _c("span", [_vm._v(_vm._s(_vm.geofencesCount))])
        : _c("ui-badge", { attrs: { color: _vm.COLORS.danger } }, [
            _vm._v(" None "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }